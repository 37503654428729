import React, { useState, useEffect } from 'react';
import { IoMenuOutline, IoCloseOutline } from 'react-icons/io5'; // Iconos de menú y cerrar
import { FiUser } from 'react-icons/fi'; // Icono de cuenta
import { AiOutlineSearch, AiOutlineShoppingCart } from 'react-icons/ai'; // Iconos de búsqueda y carrito

const HeaderMobile = ({ initialTransparent }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [isScrolled, setIsScrolled] = useState(initialTransparent ? false : true);
  const [showShopSubMenu, setShowShopSubMenu] = useState(false);
  const [showNosotrasSubMenu, setShowNosotrasSubMenu] = useState(false);

  // Controlamos el scroll al abrir/cerrar el menú, preservando la posición del scroll
  useEffect(() => {
    if (showMenu) {
      // Deshabilitamos el scroll del cuerpo
      document.body.style.overflow = 'hidden';
    } else {
      // Restauramos el scroll al cerrar el menú
      document.body.style.overflow = '';
    }
  }, [showMenu]);

  useEffect(() => {
    if (initialTransparent) {
      const handleScroll = () => {
        setIsScrolled(window.scrollY > 50);
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    } else {
      setIsScrolled(true);
    }
  }, [initialTransparent]);

  const handleMenuClick = () => {
    setShowMenu(true);
  };

  const backgroundColor = isScrolled ? 'white' : 'transparent';

  return (
    <>
      <header
        className="top-bar"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: backgroundColor,
          padding: '10px 20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          boxShadow: isScrolled ? '0px 2px 5px rgba(0,0,0,0.1)' : 'none',
          zIndex: 1000,
          transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
        }}
      >
        {/* Menú hamburguesa */}
        <div onClick={handleMenuClick} style={{ cursor: 'pointer' }}>
          <IoMenuOutline size={30} />
        </div>

        {/* Logo centrado */}
        <div className="top-bar-logo" style={{ flex: 1, textAlign: 'center' }}>
          <a href="/">
            <img
              src="https://api.mundozapatos.org/images/images/logo_nuevo.png"
              alt="MundoZapatos Logo"
              style={{
                height: 'auto',
                maxHeight: '40px',
                width: 'auto',
              }}
            />
          </a>
        </div>

        {/* Iconos de búsqueda y carrito a la derecha */}
        <div style={{ display: 'flex', gap: '10px' }}>
          <AiOutlineSearch size={25} />
          <AiOutlineShoppingCart size={25} />
        </div>
      </header>

      {/* Menú deslizante */}
      {showMenu && (
        <div
          className="mobile-menu"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '75vw', // Ancho del 75%
            height: '90vh', // Limitar el alto al 90% de la pantalla
            backgroundColor: 'white',
            zIndex: 1001,
            padding: '10px 20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            overflowY: 'auto', // Permitimos scroll solo dentro del menú
          }}
        >
          {/* Icono de cerrar */}
          <div onClick={() => setShowMenu(false)} style={{ cursor: 'pointer', marginBottom: '10px', textAlign: 'left' }}>
            <IoCloseOutline size={30} />
          </div>

          {/* Menú principal */}
          {!showShopSubMenu && !showNosotrasSubMenu && (
            <ul style={{ listStyle: 'none', padding: 0, fontFamily: 'Garamond, serif', fontSize: '18px' }}>
              <li style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                <a href="/novedades" style={{ textDecoration: 'none', color: '#000' }}>
                  NEW IN
                </a>
              </li>
              <li
                style={{
                  marginBottom: '10px',
                  borderBottom: '1px solid #ddd',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                onClick={() => setShowShopSubMenu(true)}
              >
                <span>SHOP</span> <span>{'>'}</span>
              </li>
              <li style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                <a href="/best-sellers" style={{ textDecoration: 'none', color: '#000' }}>
                  BEST-SELLERS
                </a>
              </li>
              <li
                style={{
                  marginBottom: '10px',
                  borderBottom: '1px solid #ddd',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                onClick={() => setShowNosotrasSubMenu(true)}
              >
                <span>NOSOTRAS</span> <span>{'>'}</span>
              </li>
              <li style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                <a href="/outlet" style={{ textDecoration: 'none', color: '#000' }}>
                  OUTLET
                </a>
              </li>
            </ul>
          )}

          {/* Submenú SHOP con imágenes */}
          {showShopSubMenu && (
            <div>
              <div
                onClick={() => setShowShopSubMenu(false)}
                style={{
                  cursor: 'pointer',
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <span>{'<'}</span> SHOP
              </div>
              <ul style={{ listStyle: 'none', padding: 0, fontFamily: 'Garamond, serif', fontSize: '18px', overflowY: 'auto' }}>
                <li style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                  <a href="/colecciones/zapato-plano" style={{ textDecoration: 'none', color: '#000' }}>
                    Zapato Plano
                  </a>
                </li>
                <li style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                  <a href="/colecciones/tacon-bajo" style={{ textDecoration: 'none', color: '#000' }}>
                    Tacón Bajo
                  </a>
                </li>
                <li style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                  <a href="/colecciones/tacon-alto" style={{ textDecoration: 'none', color: '#000' }}>
                    Tacón Alto
                  </a>
                </li>
                <li style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                  <a href="/colecciones/todos-los-modelos" style={{ textDecoration: 'none', color: '#000' }}>
                    Todos los Modelos
                  </a>
                </li>
              </ul>

              {/* Imágenes en paralelo */}
              <div
                style={{
                  display: 'flex',
                  overflowX: 'scroll', // Scroll horizontal para las imágenes
                  gap: '10px',
                  paddingTop: '20px',
                }}
              >
                <a href="/best-sellers" style={{ textDecoration: 'none', color: '#000' }}>
                  <img
                    src="https://api.mundozapatos.org/images/images/imagen_submenu1.png"
                    alt="Lo más vendido"
                    style={{ height: '150px', borderRadius: '5px' }}
                  />
                  <p style={{ textAlign: 'center', margin: '10px 0', fontFamily: 'Palatino Linotype, serif', fontStyle: 'italic' }}>Lo más vendido</p>
                </a>
                <a href="/novedades" style={{ textDecoration: 'none', color: '#000' }}>
                  <img
                    src="https://api.mundozapatos.org/images/images/imagen_submenu2.png"
                    alt="Lo más nuevo"
                    style={{ height: '150px', borderRadius: '5px' }}
                  />
                  <p style={{ textAlign: 'center', margin: '10px 0', fontFamily: 'Palatino Linotype, serif', fontStyle: 'italic' }}>Lo más nuevo</p>
                </a>
              </div>
            </div>
          )}

          {/* Submenú NOSOTRAS con imágenes */}
          {showNosotrasSubMenu && (
            <div>
              <div
                onClick={() => setShowNosotrasSubMenu(false)}
                style={{
                  cursor: 'pointer',
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <span>{'<'}</span> NOSOTRAS
              </div>
              <ul style={{ listStyle: 'none', padding: 0, fontFamily: 'Garamond, serif', fontSize: '18px', overflowY: 'auto' }}>
                <li style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                  <a href="/sobre-nosotras/nuestra-historia" style={{ textDecoration: 'none', color: '#000' }}>
                    Nuestra Historia
                  </a>
                </li>
                <li style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                  <a href="/sobre-nosotras/nuestros-valores" style={{ textDecoration: 'none', color: '#000' }}>
                    Nuestros Valores
                  </a>
                </li>
                <li style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                  <a href="/sobre-nosotras/nuestra-tienda" style={{ textDecoration: 'none', color: '#000' }}>
                    Nuestra Tienda
                  </a>
                </li>
                <li style={{ marginBottom: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                  <a href="/sobre-nosotras/contacto" style={{ textDecoration: 'none', color: '#000' }}>
                    Contacto
                  </a>
                </li>
              </ul>

              {/* Imágenes en paralelo */}
              <div
                style={{
                  display: 'flex',
                  overflowX: 'scroll', // Scroll horizontal para las imágenes
                  gap: '10px',
                  paddingTop: '20px',
                }}
              >
                <a href="/sobre-nosotras/nuestra-historia" style={{ textDecoration: 'none', color: '#000' }}>
                  <img
                    src="https://api.mundozapatos.org/images/images/imagen_submenu1.png"
                    alt="Nuestra Historia"
                    style={{ height: '150px', borderRadius: '5px' }}
                  />
                  <p style={{ textAlign: 'center', margin: '10px 0', fontFamily: 'Palatino Linotype, serif', fontStyle: 'italic' }}>Nuestra Historia</p>
                </a>
                <a href="/sobre-nosotras/nuestros-valores" style={{ textDecoration: 'none', color: '#000' }}>
                  <img
                    src="https://api.mundozapatos.org/images/images/imagen_submenu2.png"
                    alt="Nuestros Valores"
                    style={{ height: '150px', borderRadius: '5px' }}
                  />
                  <p style={{ textAlign: 'center', margin: '10px 0', fontFamily: 'Palatino Linotype, serif', fontStyle: 'italic' }}>Nuestros Valores</p>
                </a>
              </div>
            </div>
          )}

          {/* Icono de cuenta posicionado al pie visible */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              paddingBottom: '10px',
              position: 'absolute',
              bottom: '10px',
              left: '20px',
              textAlign: 'left',
            }}
          >
            <FiUser size={24} />
            <a href="/cuenta" style={{ textDecoration: 'none', color: '#000', fontFamily: 'Garamond, serif', fontSize: '18px' }}>
              CUENTA
            </a>
          </div>
        </div>
      )}

      {/* Fondo oscuro cuando el menú está abierto */}
      {showMenu && (
        <div
          onClick={() => setShowMenu(false)}
          style={{
            position: 'fixed',
            top: 0,
            left: '75vw',
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          }}
        ></div>
      )}
    </>
  );
};

export default HeaderMobile;
