import React from 'react';

const OutletSectionMobile = ({ headline, buttonText }) => {
  return (
    <div
      className="outlet-section"
      style={{
        position: 'relative',
        height: '90vh', // Reducimos la altura para móvil
        backgroundImage: 'url(https://api.mundozapatos.org/images/images/fondo_movil.jpg)', // Imagen específica para Outlet
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', // Centramos el contenido en móvil
        textAlign: 'center', // Centramos el texto para mejor legibilidad
        padding: '0 20px', // Añadimos padding lateral para evitar que el texto toque los bordes
      }}
    >
      <div
        style={{
          color: '#fff',
          fontWeight: 'bold',
        }}
      >
        {headline && <p style={{ fontSize: '28px', margin: '10px 0' }}>{headline}</p>} {/* Ajustamos el tamaño de la fuente para móvil */}
        {buttonText && (
          <button
            style={{
              border: '1px solid white',
              color: 'white',
              backgroundColor: 'transparent',
              padding: '10px 20px', // Reducimos el tamaño del botón para móvil
              cursor: 'pointer',
              fontSize: '16px', // Tamaño de fuente más pequeño
              transition: 'background-color 0.3s ease, color 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = 'white';
              e.currentTarget.style.color = 'black';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = 'transparent';
              e.currentTarget.style.color = 'white';
            }}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default OutletSectionMobile;
