import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const HeaderDesktop = ({ initialTransparent }) => {
  const [showColeccionesMenu, setShowColeccionesMenu] = useState(false);
  const [showSobreNosotrasMenu, setShowSobreNosotrasMenu] = useState(false);
  const [isScrolled, setIsScrolled] = useState(initialTransparent ? false : true);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (initialTransparent) {
      const handleScroll = () => {
        setIsScrolled(window.scrollY > 50);
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    } else {
      setIsScrolled(true);
    }
  }, [initialTransparent]);

  const backgroundColor = isHovered || isScrolled ? 'white' : 'transparent';

  return (
    <header
      className="top-bar"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: backgroundColor,
        padding: '10px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: (isScrolled || isHovered) ? '0px 2px 5px rgba(0,0,0,0.1)' : 'none',
        zIndex: 1000,
        borderBottom: 'none',
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="top-bar-left">
        <ul
          className="top-bar-links"
          style={{
            listStyle: 'none',
            display: 'flex',
            gap: '15px',
            margin: 0,
            fontSize: '14px',
            fontFamily: 'Garamond, serif',
          }}
        >
          <li>
            <a href="/novedades" style={{ textDecoration: 'none', color: '#000' }}>
              Novedades
            </a>
          </li>
          <li>
            <a href="/best-sellers" style={{ textDecoration: 'none', color: '#000' }}>
              Best-Sellers
            </a>
          </li>
          <li
            className="dropdown"
            style={{ position: 'relative' }}
            onMouseEnter={() => setShowColeccionesMenu(true)}
            onMouseLeave={(e) => {
              if (!e.currentTarget.contains(e.relatedTarget)) {
                setShowColeccionesMenu(false);
              }
            }}
          >
            <span className="dropdown-toggle" style={{ cursor: 'pointer', color: '#000' }}>
              Colecciones
            </span>
            {showColeccionesMenu && (
              <div
                className="dropdown-menu"
                onMouseEnter={() => setShowColeccionesMenu(true)}
                onMouseLeave={(e) => {
                  if (!e.currentTarget.contains(e.relatedTarget)) {
                    setShowColeccionesMenu(false);
                  }
                }}
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  width: '100vw',
                  backgroundColor: 'white',
                  boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
                  padding: '20px',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  zIndex: 999,
                  fontFamily: 'Garamond, serif',
                }}
              >
                <div>
                  <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                    <li>
                      <Link to="/colecciones/botas-botines" style={{ textDecoration: 'none', color: '#000', fontSize: '16px', margin: '30px 0', display: 'block', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                        Botas/Botines
                      </Link>
                    </li>
                    <li>
                      <Link to="/colecciones/sandalias" style={{ textDecoration: 'none', color: '#000', fontSize: '16px', margin: '30px 0', display: 'block', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                        Sandalias
                      </Link>
                    </li>
                    <li>
                      <Link to="/colecciones/zapatos" style={{ textDecoration: 'none', color: '#000', fontSize: '16px', margin: '30px 0', display: 'block', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                        Zapatos
                      </Link>
                    </li>
                    <li>
                      <Link to="/colecciones/all" style={{ textDecoration: 'none', color: '#000', fontSize: '16px', margin: '30px 0', display: 'block', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                        Todos los modelos
                      </Link>
                    </li>
                  </ul>
                </div>
                <div style={{ display: 'flex', gap: '30px', transform: 'translateX(-400px)', marginTop: '10px', marginBottom: '10px' }}>
                  <a href="/best-sellers" style={{ textDecoration: 'none' }}>
                    <img src="https://api.mundozapatos.org/images/images/imagen_submenu1.png" alt="Colección 1" style={{ height: '300px', margin: '10px 0' }} />
                    <p style={{ textAlign: 'center', margin: 0, fontFamily: 'Palatino Linotype, serif', fontStyle: 'italic', color: '#000' }}>Lo más vendido</p>
                  </a>
                  <a href="/novedades" style={{ textDecoration: 'none' }}>
                    <img src="https://api.mundozapatos.org/images/images/imagen_submenu2.png" alt="Colección 2" style={{ height: '300px', margin: '10px 0' }} />
                    <p style={{ textAlign: 'center', margin: 0, fontFamily: 'Palatino Linotype, serif', fontStyle: 'italic', color: '#000' }}>Lo más nuevo</p>
                  </a>
                </div>
              </div>
            )}
          </li>

          {/* Submenú "Sobre Nosotras" */}
          <li
            className="dropdown"
            style={{ position: 'relative' }}
            onMouseEnter={() => setShowSobreNosotrasMenu(true)}
            onMouseLeave={(e) => {
              if (!e.currentTarget.contains(e.relatedTarget)) {
                setShowSobreNosotrasMenu(false);
              }
            }}
          >
            <span className="dropdown-toggle" style={{ cursor: 'pointer', color: '#000' }}>
              Sobre Nosotras
            </span>
            {showSobreNosotrasMenu && (
              <div
                className="dropdown-menu"
                onMouseEnter={() => setShowSobreNosotrasMenu(true)}
                onMouseLeave={(e) => {
                  if (!e.currentTarget.contains(e.relatedTarget)) {
                    setShowSobreNosotrasMenu(false);
                  }
                }}
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  width: '100vw',
                  backgroundColor: 'white',
                  boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
                  padding: '20px',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  zIndex: 999,
                  fontFamily: 'Garamond, serif',
                }}
              >
                <div>
                  <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                    <li>
                      <Link to="/sobre-nosotras/nuestra-historia" style={{ textDecoration: 'none', color: '#000', fontSize: '16px', margin: '30px 0', display: 'block', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                        Nuestra Historia
                      </Link>
                    </li>
                    <li>
                      <Link to="/sobre-nosotras/nuestros-valores" style={{ textDecoration: 'none', color: '#000', fontSize: '16px', margin: '30px 0', display: 'block', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                        Nuestros Valores
                      </Link>
                    </li>
                    <li>
                      <Link to="/sobre-nosotras/nuestra-tienda" style={{ textDecoration: 'none', color: '#000', fontSize: '16px', margin: '30px 0', display: 'block', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                        Nuestra Tienda
                      </Link>
                    </li>
                    <li>
                      <Link to="/sobre-nosotras/contacto" style={{ textDecoration: 'none', color: '#000', fontSize: '16px', margin: '30px 0', display: 'block', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                        Contacto
                      </Link>
                    </li>
                  </ul>
                </div>
                
                <div style={{ display: 'flex', gap: '30px', transform: 'translateX(-475px)', marginTop: '10px', marginBottom: '10px' }}>
                  <a href="/sobre-nosotras/nuestra-historia" style={{ textDecoration: 'none' }}>
                    <img src="https://api.mundozapatos.org/images/images/imagen_submenu1.png" alt="Sobre Nosotras 1" style={{ height: '300px', margin: '10px 0' }} />
                    <p style={{ textAlign: 'center', margin: 0, fontFamily: 'Palatino Linotype, serif', fontStyle: 'italic', color: '#000' }}>Nuestra Historia</p>
                  </a>
                  <a href="/sobre-nosotras/nuestros-valores" style={{ textDecoration: 'none' }}>
                    <img src="https://api.mundozapatos.org/images/images/imagen_submenu2.png" alt="Sobre Nosotras 2" style={{ height: '300px', margin: '10px 0' }} />
                    <p style={{ textAlign: 'center', margin: 0, fontFamily: 'Palatino Linotype, serif', fontStyle: 'italic', color: '#000' }}>Nuestros Valores</p>
                  </a>
                </div>
              </div>
            )}
          </li>

          {/* Otras opciones del menú */}
          <li>
            <a href="/ofertas" style={{ textDecoration: 'none', color: '#000' }}>
              Ofertas
            </a>
          </li>
        </ul>
      </div>

      {/* Logo y otros elementos del header */}
      <div className="top-bar-logo" style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '400px', flex: 1 }}>
        <a href="/">
          <img
            src="https://api.mundozapatos.org/images/images/logo_nuevo.png"
            alt="MundoZapatos Logo"
            style={{
              height: 'auto',
              maxHeight: '55px',
              width: 'auto',
            }}
          />
        </a>
      </div>

      <div className="top-bar-right" style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        <div className="language-selector">
          <select style={{ padding: '5px', backgroundColor: 'transparent', border: 'none', color: '#000' }}>
            <option value="Español">ES</option>
            <option value="English">EN</option>
          </select>
        </div>
        <div className="icon-container" style={{ display: 'flex', gap: '15px' }}>
          <a href="#" className="user-icon">
            <img src="https://api.mundozapatos.org/images/images/icono_iniciar_sesion.png" alt="Cuenta" style={{ height: '27.5px', transform: 'translateY(3px)' }} />
          </a>
          <a href="#" className="search-icon">
            <img src="https://api.mundozapatos.org/images/images/buscar.png" alt="Buscar" style={{ height: '25px' }} />
          </a>
          <a href="#" className="cart-icon">
            <img src="https://api.mundozapatos.org/images/images/icono_carrito.png" alt="Carrito de compras" style={{ height: '25px' }} />
          </a>
        </div>
      </div>
    </header>
  );
};

export default HeaderDesktop;
