import React from 'react';

const AboutUsSection = () => {
  return (
    <section
      className="sobre-nosotros-section"
      style={{
        textAlign: 'center',
        padding: '50px 20px',
        fontFamily: 'Garamond, serif',
      }}
    >
      <h2 style={{ fontSize: '48px', marginBottom: '40px' }}>SOBRE NOSOTROS</h2>
      <div
        className="sobre-nosotros-images"
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '40px',
        }}
      >
        <div style={{ width: '30%' }}>
          <a href="/sobre-nosotras/nuestra-tienda" style={{ textDecoration: 'none' }}>
            <img
              src="https://api.mundozapatos.org/images/images/nuestra_tienda.png"
              alt="Nuestra Tienda"
              style={{ width: '100%', height: 'auto' }}
            />
            <p style={{ marginTop: '10px', fontSize: '24px', fontFamily: 'Palatino Linotype, serif', fontStyle: 'italic', color: '#000' }}>NUESTRA TIENDA</p>
          </a>
        </div>
        <div style={{ width: '30%' }}>
          <a href="/sobre-nosotras/nuestra-historia" style={{ textDecoration: 'none' }}>
            <img
              src="https://api.mundozapatos.org/images/images/nuestra_tienda.png"
              alt="Nuestra Historia"
              style={{ width: '100%', height: 'auto' }}
            />
            <p style={{ marginTop: '10px', fontSize: '24px', fontFamily: 'Palatino Linotype, serif', fontStyle: 'italic', color: '#000' }}>NUESTRA HISTORIA</p>
          </a>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
