import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Importar Link para los enlaces a productos

const ProductList = ({ categoryId }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    axios.get(`https://api.mundozapatos.org/wp-json/wc/v3/products?category=${categoryId}&consumer_key=ck_d57dfe5b00989ed40449e571d4de13f664d829f7&consumer_secret=cs_833baa96ab651bfc7783faabe31087ec4cc08931`)
      .then(response => setProducts(response.data))
      .catch(error => console.error('Error fetching products', error));
  }, [categoryId]);

  return (
    <div className="product-list" style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)', // Cambiado a 3 columnas
      gap: '20px',
      padding: '20px',
      maxWidth: '1200px', // Opcional: limitar el ancho máximo
      margin: '0 auto' // Centrar la cuadrícula
    }}>
    
      {products.map(product => (
        <div key={product.id} className="product-item" style={{ textAlign: 'center' }}>
          <Link to={`/producto/${product.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src={product.images[0]?.src} alt={product.name} style={{ width: '100%', height: 'auto', cursor: 'pointer' }} />
            <h3 style={{ margin: '10px 0', fontSize: '18px', fontFamily: 'Palatino Linotype, serif' }}>{product.name}</h3>
          </Link>
          <p style={{ fontFamily: 'Palatino Linotype, serif' }}>{product.price} €</p>

        </div>
      ))}
    </div>
  );
};

export default ProductList;
