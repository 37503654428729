import React from 'react';

const HighlightSectionsMobile = ({ selectedSection, staticSections, dynamicSections, fadeOut, handleSectionChange }) => {
  // Usar imágenes dinámicas si están disponibles, de lo contrario usar las estáticas.
  const sectionsToDisplay = dynamicSections[selectedSection].length > 0
    ? dynamicSections[selectedSection]
    : staticSections[selectedSection];

  return (
    <div className="highlight-sections" style={{ padding: '0 0 50px 0' }}>
      {/* Categoría Imprescindibles */}
      <section className="category-section" style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h2 style={{ fontSize: '16px', fontWeight: 'normal', marginBottom: '10px' }}>IMPRESCINDIBLES</h2>

        {/* Sección deslizable para los botones */}
        <div
          className="sliding-buttons"
          style={{
            display: 'flex',
            overflowX: 'auto', // Habilita el desplazamiento horizontal
            whiteSpace: 'nowrap',
            padding: '0 5px',
            gap: '15px',
            fontFamily: 'Garamond, serif',
          }}
        >
          {Object.keys(staticSections).map((section) => (
            <button
              key={section}
              onClick={() => handleSectionChange(section)}
              style={{
                background: 'none',
                border: 'none',
                fontSize: '18px',
                fontWeight: selectedSection === section ? 'bold' : 'normal',
                cursor: 'pointer',
                textDecoration: selectedSection === section ? 'underline' : 'none',
                whiteSpace: 'nowrap',
                padding: '5px 10px',  // Ajusta este valor para reducir el espacio interno del botón
                margin: '0',          // Asegúrate de que no haya margen entre botones
              }}
            >
              {section}
            </button>
          ))}

        </div>

        {/* Imágenes deslizable debajo de "IMPRESCINDIBLES" */}
        <div
          className="images-container"
          style={{
            display: 'flex',
            overflowX: 'scroll',
            gap: '10px',
            margin: '20px 0',
            padding: '0 10px',
            transition: 'opacity 1s ease-in-out',
            opacity: fadeOut ? 0 : 1,
          }}
        >
          {sectionsToDisplay.map((image) => (
            <img
              key={image.alt}
              src={image.src}
              alt={image.alt}
              style={{ width: 'calc(60% - 10px)', height: 'auto' }} // Cada imagen ocupa la mitad del ancho de la pantalla
            />
          ))}
        </div>
      </section>

      {/* Botón Ver Colección Completa */}
      <div style={{ textAlign: 'center', margin: '50px 0' }}>
        <button
          style={{
            border: '1px solid black',
            color: 'white',
            backgroundColor: 'black',
            padding: '15px 30px',
            cursor: 'pointer',
            fontSize: '18px',
            transition: 'background-color 0.3s ease, color 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = 'white';
            e.currentTarget.style.color = 'black';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = 'black';
            e.currentTarget.style.color = 'white';
          }}
        >
          VER COLECCIÓN COMPLETA
        </button>
      </div>

      {/* Tres imágenes en columna con nombres de sección */}
      <div className="full-width-images" style={{ marginBottom: '0', padding: '0' }}>
        <div style={{ position: 'relative', width: '100%', marginBottom: '0' }}>
          <a href="/botas-botines">
            <img src="/images/botas4.jpg" alt="BOTAS/BOTINES" style={{ width: '100%', height: 'auto', margin: '0', padding: '0' }} />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontSize: '36px',
                fontWeight: 'bold',
                fontFamily: 'Garamond, serif',
              }}
            >
              BOTAS/BOTINES
            </div>
          </a>
        </div>
        <div style={{ position: 'relative', width: '100%', marginBottom: '0' }}>
          <a href="/sandalias">
            <img src="/images/sandalias2.jpg" alt="SANDALIAS" style={{ width: '100%', height: 'auto', margin: '0', padding: '0' }} />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontSize: '36px',
                fontWeight: 'bold',
                fontFamily: 'Garamond, serif',
              }}
            >
              SANDALIAS
            </div>
          </a>
        </div>
        <div style={{ position: 'relative', width: '100%', marginBottom: '0' }}>
          <a href="/zapatos">
            <img src="/images/zapatos2.jpg" alt="ZAPATOS" style={{ width: '100%', height: 'auto', margin: '0', padding: '0' }} />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontSize: '36px',
                fontWeight: 'bold',
                fontFamily: 'Garamond, serif',
              }}
            >
              ZAPATOS
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HighlightSectionsMobile;
