// SlidingBanner.js
import React from 'react';

const SlidingBanner = () => {
  return (
    <div
      className="sliding-banner"
      style={{
        position: 'relative',
        height: '150px',
        overflow: 'hidden',
        marginBottom: '20px',
      }}
    >
      <div
        className="sliding-content"
        style={{
          display: 'flex',
          alignItems: 'center',
          animation: 'slide 18s linear infinite',
          width: '300%',
        }}
      >
        {[...Array(3)].map((_, index) => (
          <React.Fragment key={index}>
            {/* Slide Item 1 */}
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '80px', flexShrink: 0 }}>
              <p style={{ fontSize: '24px', margin: '0 20px 0 0', color: '#000', fontFamily: 'Garamond, serif' }}>COMODIDAD</p>
              <img
                src="https://api.mundozapatos.org/images/images/imagen_letrero1.png"
                alt="Comodidad"
                style={{ height: '120px', width: 'auto', objectFit: 'contain' }}
              />
            </div>

            {/* Slide Item 2 */}
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '80px', flexShrink: 0 }}>
              <p style={{ fontSize: '24px', margin: '0 20px 0 0', color: '#000', fontFamily: 'Garamond, serif' }}>DISEÑO</p>
              <img
                src="https://api.mundozapatos.org/images/images/imagen_letrero2.jpg"
                alt="Diseño"
                style={{ height: '120px', width: 'auto', objectFit: 'contain' }}
              />
            </div>

            {/* Slide Item 3 */}
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '80px', flexShrink: 0 }}>
              <p style={{ fontSize: '24px', margin: '0 20px 0 0', color: '#000', fontFamily: 'Garamond, serif' }}>ESTILO</p>
              <img
                src="https://api.mundozapatos.org/images/images/imagen_letrero3.png"
                alt="Estilo"
                style={{ height: '120px', width: 'auto', objectFit: 'contain' }}
              />
            </div>
          </React.Fragment>
        ))}
      </div>

      <style>
        {`
          @keyframes slide {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-16%);
            }
          }
        `}
      </style>
    </div>
  );
};

export default SlidingBanner;
