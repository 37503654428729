import React from 'react';

const AboutUsSectionMobile = () => {
  return (
    <section
      className="sobre-nosotros-section"
      style={{
        textAlign: 'center',
        padding: '30px 15px', // Reducimos el padding para móviles
        fontFamily: 'Garamond, serif',
      }}
    >
      <h2 style={{ fontSize: '32px', marginBottom: '20px' }}>SOBRE NOSOTROS</h2> {/* Tamaño de fuente reducido */}
      <div
        className="sobre-nosotros-images"
        style={{
          display: 'flex',
          flexDirection: 'column', // Cambiamos a columna para que se vean una debajo de la otra
          alignItems: 'center',
          gap: '20px', // Reducimos el espacio entre imágenes
        }}
      >
        <div style={{ width: '70%' }}> {/* Hacemos las imágenes más grandes para móvil */}
          <a href="/sobre-nosotras/nuestra-tienda" style={{ textDecoration: 'none' }}> {/* Quitamos el aspecto de hipervínculo */}
            <img
              src="https://api.mundozapatos.org/images/images/nuestra_tienda.png"
              alt="Nuestra Tienda"
              style={{ width: '100%', height: 'auto' }}
            />
            <p style={{ marginTop: '10px', fontSize: '18px', fontFamily: 'Palatino Linotype, serif', fontStyle: 'italic', color: '#000' }}>NUESTRA TIENDA</p> {/* Aplicamos el mismo estilo de letra */}
          </a>
        </div>
        <div style={{ width: '70%' }}>
          <a href="/sobre-nosotras/nuestra-historia" style={{ textDecoration: 'none' }}> {/* Quitamos el aspecto de hipervínculo */}
            <img
              src="https://api.mundozapatos.org/images/images/nuestra_tienda.png"
              alt="Nuestra Historia"
              style={{ width: '100%', height: 'auto' }}
            />
            <p style={{ marginTop: '10px', fontSize: '18px', fontFamily: 'Palatino Linotype, serif', fontStyle: 'italic', color: '#000' }}>NUESTRA HISTORIA</p> {/* Aplicamos el mismo estilo de letra */}
          </a>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSectionMobile;
