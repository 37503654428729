import React from 'react';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#f8f8f8', padding: '40px 20px', fontFamily: 'Garamond, serif' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', maxWidth: '1200px', margin: '0 auto' }}>
        {/* Lista Exclusiva */}
        <div style={{ flex: '1 1 250px', marginBottom: '20px' }}>
          <h3 style={{ fontSize: '18px', marginBottom: '10px' }}>LISTA EXCLUSIVA</h3>
          <p style={{ fontSize: '14px', marginBottom: '10px' }}>Suscríbete y ahorra un 10% en tu primera compra.</p>
          <input
            type="email"
            placeholder="Correo electrónico"
            style={{
              width: '100%',
              padding: '10px',
              border: '1px solid #ccc',
              marginBottom: '10px',
            }}
          />
          <button
            style={{
              backgroundColor: 'black',
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              cursor: 'pointer',
            }}
          >
            SUSCRIBIRSE
          </button>
        </div>
        
        {/* Sobre NOSOTROS */}
        <div style={{ flex: '1 1 200px', marginBottom: '20px' }}>
          <h3 style={{ fontSize: '18px', marginBottom: '10px' }}>SOBRE NOSOTROS</h3>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li style={{ marginBottom: '5px' }}><a href="/nuestra-tienda" style={{ textDecoration: 'none', color: '#000' }}>Nuestra Tienda</a></li>
            <li style={{ marginBottom: '5px' }}><a href="/contacto" style={{ textDecoration: 'none', color: '#000' }}>Contacto</a></li>
          </ul>
        </div>

        {/* Ayuda */}
        <div style={{ flex: '1 1 200px', marginBottom: '20px' }}>
          <h3 style={{ fontSize: '18px', marginBottom: '10px' }}>AYUDA</h3>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li style={{ marginBottom: '5px' }}><a href="/cuidado-calzado" style={{ textDecoration: 'none', color: '#000' }}>Cuidado del calzado</a></li>
            <li style={{ marginBottom: '5px' }}><a href="/guia-tallas" style={{ textDecoration: 'none', color: '#000' }}>Guía de tallas</a></li>
            <li style={{ marginBottom: '5px' }}><a href="/preguntas-frecuentes" style={{ textDecoration: 'none', color: '#000' }}>Preguntas Frecuentes</a></li>
            <li style={{ marginBottom: '5px' }}><a href="/envios" style={{ textDecoration: 'none', color: '#000' }}>Envíos</a></li>
            <li style={{ marginBottom: '5px' }}><a href="/cambios-devoluciones" style={{ textDecoration: 'none', color: '#000' }}>Cambios y Devoluciones</a></li>
            <li style={{ marginBottom: '5px' }}><a href="/aviso-legal" style={{ textDecoration: 'none', color: '#000' }}>Aviso Legal</a></li>
          </ul>
        </div>

        {/* Contacto */}
        <div style={{ flex: '1 1 300px', marginBottom: '20px' }}>
          <h3 style={{ fontSize: '18px', marginBottom: '10px' }}>CONTACTO</h3>
          <p style={{ fontSize: '14px' }}>Atención al Cliente</p>
          <p style={{ fontSize: '14px' }}>Mail: <a href="mailto:web@mundozapatos.com" style={{ color: '#000' }}>wweb@mundozapatos.com</a></p>
          <p style={{ fontSize: '14px' }}>Whatsapp: +34 606301486</p>
        </div>
      </div>

      {/* Redes Sociales */}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', gap: '20px' }}>
        <a href="#"><img src="https://api.mundozapatos.org/images/images/facebook.png" alt="Facebook" style={{ width: '25px', height: '25px' }} /></a>
        <a href="#"><img src="https://api.mundozapatos.org/images/images/instagram.png" alt="Instagram" style={{ width: '25px', height: '25px' }} /></a>
        <a href="#"><img src="https://api.mundozapatos.org/images/images/tik-tok.png" alt="TikTok" style={{ width: '25px', height: '25px' }} /></a>
        <a href="#"><img src="https://api.mundozapatos.org/images/images/whatsapp.png" alt="WhatsApp" style={{ width: '25px', height: '25px' }} /></a>
      </div>

      {/* Copyright */}
      <div style={{ textAlign: 'center', marginTop: '30px', fontSize: '14px' }}>
        <p>&copy; 2024 MundoZapatos. Todos los derechos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
