import React from 'react';

const LogosSectionMobile = () => {
  return (
    <section
      className="logos-section-mobile"
      style={{
        padding: '30px 20px',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '20px',
        fontFamily: 'Garamond, serif',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <img
          src="https://api.mundozapatos.org/images/images/tienda.png"
          alt="Tienda Física"
          style={{ width: '60px', height: '60px', objectFit: 'contain', marginBottom: '10px' }}
        />
        <p style={{ fontSize: '14px', margin: '0' }}>TIENDA FÍSICA</p>
        <p style={{ fontSize: '12px', margin: '5px 0 0' }}>Avenida Ronda de los Tejares 19, Córdoba</p>
      </div>
      <div style={{ textAlign: 'center' }}>
        <img
          src="https://api.mundozapatos.org/images/images/pago-seguro.png"
          alt="Pago 100% Seguro"
          style={{ width: '60px', height: '60px', objectFit: 'contain', marginBottom: '10px' }}
        />
        <p style={{ fontSize: '14px', margin: '0' }}>PAGO 100% SEGURO</p>
        <p style={{ fontSize: '12px', margin: '5px 0 0' }}>Visa, Mastercard, Amex, Paypal...</p>
      </div>
      <div style={{ textAlign: 'center' }}>
        <img
          src="https://api.mundozapatos.org/images/images/enviado.png"
          alt="Envíos y Devoluciones"
          style={{ width: '60px', height: '60px', objectFit: 'contain', marginBottom: '10px' }}
        />
        <p style={{ fontSize: '14px', margin: '0' }}>ENVÍOS Y DEVOLUCIONES</p>
        <p style={{ fontSize: '12px', margin: '5px 0 0' }}>Envíos y primer cambio gratis en península</p>
      </div>
      <div style={{ textAlign: 'center' }}>
        <img
          src="https://api.mundozapatos.org/images/images/servicio-al-cliente.png"
          alt="Atención Personalizada"
          style={{ width: '60px', height: '60px', objectFit: 'contain', marginBottom: '10px' }}
        />
        <p style={{ fontSize: '14px', margin: '0' }}>ATENCIÓN PERSONALIZADA</p>
        <p style={{ fontSize: '12px', margin: '5px 0 0' }}>web@mundozapatos.com o +34 606301486</p>
      </div>
    </section>
  );
};

export default LogosSectionMobile;
