import React, { useState, useEffect } from 'react';
import HeroSectionDesktop from './HeroSectionDesktop';
import HeroSectionMobile from './HeroSectionMobile';

const HeroSection = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      const mobileCheck = window.innerWidth <= 768;
      if (isMobile !== mobileCheck) {
        setIsMobile(mobileCheck);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Para ejecutar la verificación una vez al montar

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return isMobile ? <HeroSectionMobile {...props} /> : <HeroSectionDesktop {...props} />;
};

export default HeroSection;
