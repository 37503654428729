import React from 'react';
import { Link } from 'react-router-dom';

const HighlightSections = ({ selectedSection, staticSections, dynamicSections, fadeOut, handleSectionChange }) => {
  // Usar imágenes dinámicas si están disponibles, de lo contrario usar las estáticas.
  const sectionsToDisplay = dynamicSections[selectedSection].length > 0
    ? dynamicSections[selectedSection]
    : staticSections[selectedSection];

  return (
    <div className="highlight-sections" style={{ padding: '50px 20px' }}>
      {/* Categoría Imprescindibles */}
      <section className="category-section" style={{ textAlign: 'center' }}>
        <h2 style={{ fontSize: '30px', marginBottom: '20px', fontWeight: 'bold' }}>IMPRESCINDIBLES</h2>
        <div className="category-buttons" style={{ display: 'flex', justifyContent: 'center', gap: '60px', marginBottom: '40px', fontFamily: 'Garamond, serif' }}>
          {Object.keys(staticSections).map((section) => (
            <button
              key={section}
              onClick={() => handleSectionChange(section)}
              style={{
                background: 'none',
                border: 'none',
                fontSize: '24px',
                fontWeight: selectedSection === section ? 'bold' : 'normal',
                cursor: 'pointer',
                textDecoration: selectedSection === section ? 'underline' : 'none',
              }}
            >
              {section}
            </button>
          ))}
        </div>
        <div className="images-container" style={{ display: 'flex', justifyContent: 'center', gap: '20px', transition: 'opacity 1s ease-in-out', opacity: fadeOut ? 0 : 1, margin: '0 20px' }}>
          {sectionsToDisplay.map((image) => (
            <img key={image.alt} src={image.src} alt={image.alt} style={{ width: 'calc(25% - 10px)', height: 'auto' }} />
          ))}
        </div>
      </section>

      {/* Botón Ver Colección Completa */}
      <div style={{ textAlign: 'center', margin: '50px 0' }}>
        <button
          style={{
            border: '1px solid black',
            color: 'white',
            backgroundColor: 'black',
            padding: '15px 30px',
            cursor: 'pointer',
            fontSize: '18px',
            transition: 'background-color 0.3s ease, color 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = 'white';
            e.currentTarget.style.color = 'black';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = 'black';
            e.currentTarget.style.color = 'white';
          }}
        >
          VER COLECCIÓN COMPLETA
        </button>
      </div>

      {/* Tres imágenes en paralelo con nombres de sección */}
      <div className="full-width-images" style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '20px' }}>
        <div style={{ position: 'relative', flex: 1 }}>
          <Link to="/botas-botines">
            <img src="https://api.mundozapatos.org/images/images/botas4.jpg" alt="BOTAS/BOTINES" style={{ width: '100%', height: 'auto' }} />
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '36px', fontWeight: 'bold', fontFamily: 'Garamond, serif' }}>
              BOTAS/BOTINES
            </div>
          </Link>
        </div>
        <div style={{ position: 'relative', flex: 1 }}>
          <Link to="/sandalias">
            <img src="https://api.mundozapatos.org/images/images/sandalias2.jpg" alt="SANDALIAS" style={{ width: '100%', height: 'auto' }} />
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '36px', fontWeight: 'bold', fontFamily: 'Garamond, serif' }}>
              SANDALIAS
            </div>
          </Link>
        </div>
        <div style={{ position: 'relative', flex: 1 }}>
          <Link to="/zapatos">
            <img src="https://api.mundozapatos.org/images/images/zapatos2.jpg" alt="ZAPATOS" style={{ width: '100%', height: 'auto' }} />
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '36px', fontWeight: 'bold', fontFamily: 'Garamond, serif' }}>
              ZAPATOS
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HighlightSections;
