import React, { useState, useEffect } from 'react';
import './App.css';
import HeroSection from './components/HeroSection';
import HeroSectionMobile from './components/HeroSectionMobile';
import Footer from './components/Footer';
import SlidingBanner from './components/SlidingBanner';
import AboutUsSection from './components/AboutUsSection';
import LogosSection from './components/LogosSection';
import LogosSectionMobile from './components/LogosSectionMobile';
import HighlightSections from './components/HighlightSections';
import FullScreenSectionDesktop from './components/FullScreenSectionDesktop';
import OutletSection from './components/OutletSection';
import HighlightSectionsMobile from './components/HighlightSectionsMobile';
import SlidingBannerMobile from './components/SlidingBannerMobile';
import OutletSectionMobile from './components/OutletSectionMobile';
import AboutUsSectionMobile from './components/AboutUsSectionMobile';
import BotasBotinesDesktop from './pages/BotasBotinesDesktop';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProductDetail from './pages/ProductDetail';


const staticSections = {
  'BOTAS/BOTINES': [
    { src: '/images/botas1.jpg', alt: 'Ejemplo 1' },
    { src: '/images/botas2.jpg', alt: 'Ejemplo 2' },
    { src: '/images/botas3.jpg', alt: 'Ejemplo 3' },
    { src: '/images/botas4.jpg', alt: 'Ejemplo 4' }
  ],
  SANDALIAS: [
    { src: '/images/sandalias1.jpg', alt: 'Ejemplo 1' },
    { src: '/images/sandalias2.jpg', alt: 'Ejemplo 2' },
    { src: '/images/sandalias3.jpg', alt: 'Ejemplo 3' },
    { src: '/images/sandalias1.jpg', alt: 'Ejemplo 4' }
  ],
  ZAPATOS: [
    { src: '/images/zapatos1.jpg', alt: 'Ejemplo 1' },
    { src: '/images/zapatos2.jpg', alt: 'Ejemplo 2' },
    { src: '/images/zapatos3.jpg', alt: 'Ejemplo 3' },
    { src: '/images/zapatos4.jpg', alt: 'Ejemplo 4' }
  ]
};

function App() {
  const [selectedSection, setSelectedSection] = useState('BOTAS/BOTINES');
  const [fadeOut, setFadeOut] = useState(false);
  const [sections, setSections] = useState({
    'BOTAS/BOTINES': [],
    'SANDALIAS': [],
    'ZAPATOS': []
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch('https://api.mundozapatos.org/wp-json/wc/v3/products', {
          headers: {
            Authorization: 'Basic ' + btoa('ck_d57dfe5b00989ed40449e571d4de13f664d829f7:cs_833baa96ab651bfc7783faabe31087ec4cc08931')
          },
        });
        const products = await response.json();

        const botasBotines = products.filter(product => product.categories.some(cat => cat.name === 'Botas/Botines'));
        const sandalias = products.filter(product => product.categories.some(cat => cat.name === 'Sandalias'));
        const zapatos = products.filter(product => product.categories.some(cat => cat.name === 'Zapatos'));

        setSections({
          'BOTAS/BOTINES': botasBotines.map(product => ({ src: product.images[0]?.src, alt: product.name })),
          'SANDALIAS': sandalias.map(product => ({ src: product.images[0]?.src, alt: product.name })),
          'ZAPATOS': zapatos.map(product => ({ src: product.images[0]?.src, alt: product.name }))
        });
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchImages();
  }, []);

  const handleSectionChange = (section) => {
    setFadeOut(true);
    setTimeout(() => {
      setSelectedSection(section);
      setFadeOut(false);
    }, 900);
  };

  return (
    <Router>
      <div className="App" style={{ fontFamily: 'Garamond, serif' }}>
        <Routes>
          <Route path="/" element={
            <>
              {isMobile ? (
                <HeroSectionMobile
                  headline="NUEVA COLECCIÓN AW24/25"
                  subheadline="PENSADA PARA MUJERES REALES"
                  buttonText="COMPRA AHORA"
                  initialTransparent={true}
                />
              ) : (
                <HeroSection
                  headline="NUEVA COLECCIÓN AW24/25"
                  subheadline="PENSADA PARA MUJERES REALES"
                  buttonText="COMPRA AHORA"
                  initialTransparent={true}
                />
              )}

              <main style={{ marginTop: '80px' }}>
                {isMobile ? (
                  <>
                    <HighlightSectionsMobile
                      selectedSection={selectedSection}
                      staticSections={staticSections}
                      dynamicSections={sections}
                      fadeOut={fadeOut}
                      handleSectionChange={handleSectionChange}
                    />
                    <SlidingBannerMobile />
                    <OutletSectionMobile headline="OUTLET 24/25" buttonText="IR AHORA" />
                    <AboutUsSectionMobile />
                    <LogosSectionMobile />
                  </>
                ) : (
                  <>
                    <HighlightSections
                      selectedSection={selectedSection}
                      staticSections={staticSections}
                      dynamicSections={sections}
                      fadeOut={fadeOut}
                      handleSectionChange={handleSectionChange}
                    />
                    <SlidingBanner />
                    <OutletSection headline="OUTLET 24/25" buttonText="IR AHORA" />
                    <AboutUsSection />
                    <LogosSection />
                  </>
                )}
              </main>
            </>
          } />
          <Route path="/colecciones/botas-botines" element={<BotasBotinesDesktop />} />
          <Route path="/producto/:id" element={<ProductDetail />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
