import React from 'react';

const SlidingBannerMobile = () => {
  return (
    <div
      className="sliding-banner"
      style={{
        position: 'relative',
        height: '120px', // Ajuste para móvil
        overflow: 'hidden',
        marginBottom: '20px',
      }}
    >
      <div
        className="sliding-content"
        style={{
          display: 'flex',
          alignItems: 'center',
          animation: 'slideMobile 15s linear infinite', // Animación más larga para que se vea continua
          width: '200%', // Duplicar el ancho para un deslizamiento continuo
        }}
      >
        {[...Array(2)].map((_, index) => (
          <React.Fragment key={index}>
            {/* Slide Item 1 */}
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '40px', flexShrink: 0 }}>
              <p style={{ fontSize: '18px', margin: '0 10px 0 0', color: '#000', fontFamily: 'Garamond, serif' }}>COMODIDAD</p>
              <img
                src="https://api.mundozapatos.org/images/images/imagen_letrero1.png"
                alt="Comodidad"
                style={{ height: '100px', width: 'auto', objectFit: 'contain' }} // Ajuste de tamaño para móvil
              />
            </div>

            {/* Slide Item 2 */}
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '40px', flexShrink: 0 }}>
              <p style={{ fontSize: '18px', margin: '0 10px 0 0', color: '#000', fontFamily: 'Garamond, serif' }}>DISEÑO</p>
              <img
                src="https://api.mundozapatos.org/images/images/imagen_letrero2.jpg"
                alt="Diseño"
                style={{ height: '100px', width: 'auto', objectFit: 'contain' }}
              />
            </div>

            {/* Slide Item 3 */}
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '40px', flexShrink: 0 }}>
              <p style={{ fontSize: '18px', margin: '0 10px 0 0', color: '#000', fontFamily: 'Garamond, serif' }}>ESTILO</p>
              <img
                src="https://api.mundozapatos.org/images/images/imagen_letrero3.png"
                alt="Estilo"
                style={{ height: '100px', width: 'auto', objectFit: 'contain' }}
              />
            </div>
          </React.Fragment>
        ))}
      </div>

      <style>
        {`
          @keyframes slideMobile {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-85%); /* Se mueve exactamente la mitad del ancho del contenedor */
            }
          }
        `}
      </style>
    </div>
  );
};

export default SlidingBannerMobile;
