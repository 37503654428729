// OutletSection.js
import React from 'react';

const OutletSection = ({ headline, buttonText }) => {
  return (
    <div
      className="outlet-section"
      style={{
        position: 'relative',
        height: '100vh',
        backgroundImage: 'url(https://api.mundozapatos.org/images/images/imagen_ejemplo.png)', // Imagen específica para Outlet
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <div
        style={{
          marginLeft: '10%',
          color: '#fff',
          fontWeight: 'bold',
          textAlign: 'left',
        }}
      >
        {headline && <p style={{ fontSize: '48px', margin: '10px 0' }}>{headline}</p>}
        {buttonText && (
          <button
            style={{
              border: '1px solid white',
              color: 'white',
              backgroundColor: 'transparent',
              padding: '15px 30px',
              cursor: 'pointer',
              fontSize: '18px',
              transition: 'background-color 0.3s ease, color 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = 'white';
              e.currentTarget.style.color = 'black';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = 'transparent';
              e.currentTarget.style.color = 'white';
            }}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default OutletSection;
