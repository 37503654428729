import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    axios.get(`https://api.mundozapatos.org/wp-json/wc/v3/products/${id}?consumer_key=ck_d57dfe5b00989ed40449e571d4de13f664d829f7&consumer_secret=cs_833baa96ab651bfc7783faabe31087ec4cc08931`)
      .then(response => setProduct(response.data))
      .catch(error => console.error('Error fetching product', error));
  }, [id]);

  if (!product) return <p>Loading...</p>;

  return (
    <div style={{ padding: '20px' }}>
      <img src={product.images[0]?.src} alt={product.name} style={{ width: '100%', height: 'auto' }} />
      <h1>{product.name}</h1>
      <p>{product.description}</p>
      <p><strong>{product.price} €</strong></p>
      <button style={{ padding: '10px 20px', backgroundColor: 'black', color: 'white', border: 'none', cursor: 'pointer' }}>
        Comprar Ahora
      </button>
    </div>
  );
};

export default ProductDetail;
