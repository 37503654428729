import React from 'react';
import ProductList from '../components/ProductList';
import HeaderDesktop from '../components/HeaderDesktop';

const BotasBotinesDesktop = () => {
  return (
    <div>
      <HeaderDesktop />
      <div
        style={{
          height: '25vh',
          backgroundImage: 'url("https://api.mundozapatos.org/images/images/imagen_ejemplo.png")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></div>
      <div className="products-section" style={{ padding: '20px' }}>
        <h2>Botas/Botines</h2>
        <ProductList categoryId="17" /> {/* Pasando el ID directamente */}
      </div>
    </div>
  );
};

export default BotasBotinesDesktop;
