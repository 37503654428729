import React from 'react';
import Header from './Header';

const HeroSectionDesktop = ({ headline, subheadline, buttonText, initialTransparent }) => {
  return (
    <div
      className="hero-section"
      style={{
        position: 'relative',
        height: '100vh',
        backgroundImage: 'url(https://api.mundozapatos.org/images/images/imagen_ejemplo.png)', // Imagen de fondo
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Header initialTransparent={initialTransparent} />
      <div
        className="full-screen-content"
        style={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            marginLeft: '10%',
            color: '#fff',
            fontWeight: 'bold',
            textAlign: 'left',
          }}
        >
          {subheadline && <p style={{ fontSize: '18px', margin: 0 }}>{subheadline}</p>}
          {headline && <p style={{ fontSize: '48px', margin: '10px 0' }}>{headline}</p>}
          {buttonText && (
            <button
              style={{
                border: '1px solid white',
                color: 'white',
                backgroundColor: 'transparent',
                padding: '15px 30px',
                cursor: 'pointer',
                fontSize: '18px',
                transition: 'background-color 0.3s ease, color 0.3s ease',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = 'white';
                e.currentTarget.style.color = 'black';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent';
                e.currentTarget.style.color = 'white';
              }}
            >
              {buttonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroSectionDesktop;
